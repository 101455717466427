'use client';

import {useRouter} from 'next/navigation';
import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import type {MyList} from 'server/apiHandlers/getMyListsHandler';
import {myListsQuery} from 'src/utils/queries';
import fetcher from 'src/utils/fetcher';

const updateLists = (listNameId: string, placeId: string, isAdding: boolean) => (myLists: MyList[]) => {
  const list = myLists.find((l) => l.nameId === listNameId);

  if (!list) return myLists;

  if (isAdding) {
    list.places.push(placeId);
  } else {
    list.places = list.places.filter((p) => p !== placeId);
  }

  return myLists;
};

type Options = {
  initialMyLists?: MyList[];
  onUpdate?: () => void;
  session: IronSession | null;
};

const useMyLists = ({initialMyLists, onUpdate, session}: Options) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const initialDataOptions = initialMyLists ? {placeholderData: initialMyLists} : {};

  const {
    data: myLists = [],
    isLoading,
    refetch
  } = useQuery({
    queryKey: ['myLists'],
    queryFn: () => fetcher<MyList[]>(myListsQuery).catch(() => []),
    ...initialDataOptions,
    enabled: Boolean(session)
  });

  const {mutateAsync: updateList} = useMutation({
    mutationFn: ({body, listNameId}: any) =>
      fetcher({url: `/api/lists/${session?.nameId}/${listNameId}`, options: {method: 'PUT'}, body}),
    onSettled: async () => {
      router.refresh();
      return await queryClient.invalidateQueries({queryKey: ['myLists']});
    }
  });

  const addPlaceToList = async (placeId: string, listNameId: string, isSelected: boolean) => {
    const body = isSelected ? {add: placeId} : {remove: placeId};

    await queryClient.cancelQueries({queryKey: ['myLists']});

    queryClient.setQueryData(['myLists'], updateLists(listNameId, placeId, isSelected));

    await updateList({body, listNameId});

    if (onUpdate) onUpdate();
  };

  return {
    myLists: session ? myLists || [] : [],
    addPlaceToList,
    isLoading: session ? isLoading : false,
    refetchMyLists: refetch
  };
};

export default useMyLists;
