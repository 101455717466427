'use client';

import {forwardRef} from 'react';
import Image, {ImageLoaderProps, type ImageProps} from 'next/image';

// const normalizeSrc = (src: string) => {
//   return src.startsWith('/') ? src.slice(1) : src.replace('https://', 'https:/');
// };

// const cloudflareLoader = ({src, width, quality}: ImageLoaderProps) => {
//   const params = ['format=auto'];

//   if (quality) {
//     params.push(`quality=${quality}`);
//   }

//   if (width) {
//     params.push(`width=${width}`);
//   }

//   const paramsString = params.join(',');

//   return `https://humbo.com/cdn-cgi/image/${paramsString}/${normalizeSrc(src)}`;
// };

const FastImage = forwardRef((props: ImageProps, ref: any) => {
  return (
    <Image
      {...props}
      // loader={cloudflareLoader}
      // src={isRemoteImage ? getCloudflareSrc(props.src as string, props.width, props.quality) : props.src}
      ref={ref}
    />
  );
});

export default FastImage;
