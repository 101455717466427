'use client';

import {useState} from 'react';
import dynamic from 'next/dynamic';
import {twMerge} from 'tailwind-merge';
import {useRouter} from 'next/navigation';
import {bucket, checkLine, checkboxBlankCircleLine, checkboxCircleFill, moreFill, starFill} from 'src/utils/icons';
import Dropdown, {type DropdownMenuItem} from './Dropdown';
import useMyLists from 'src/hooks/useMyLists';
import BaseButton from './BaseButton';

const RatePlaceModal = dynamic(() => import('./rate-place/RatePlaceModal'));
const AuthModal = dynamic(() => import('./auth/AuthModal'));

type Props = {
  place: Place;
  session: IronSession | null;
};

const PlaceCardDropdown = ({place, session}: Props) => {
  const router = useRouter();

  const {myLists, isLoading, addPlaceToList, refetchMyLists} = useMyLists({onUpdate: router.refresh, session});
  const [isRateModalOpen, setIsRateModalOpen] = useState(false);
  const [isAuthModalOpened, setIsAuthModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  if (isLoading) return null;

  const isPlaceSaved = myLists.some((list) => list.places.includes(place._id));

  const visitedList = myLists.find((list) => list.nameId === 'visited-places');
  const isPlaceRated = visitedList?.places?.includes(place._id);

  const icon = isPlaceRated ? checkLine : isPlaceSaved ? bucket : moreFill;

  const listsWithoutVisited = myLists.filter((list) => list.nameId !== 'visited-places');

  const items: DropdownMenuItem[] = session
    ? [
        {
          text: isPlaceRated ? 'My review' : 'Rate',
          icon: starFill,
          onAction: () => setIsRateModalOpen(true)
        },
        'separator',
        ...listsWithoutVisited.map((list): DropdownMenuItem => {
          const isPlaceInList = list.places.includes(place._id);

          return {
            text: list.name,
            icon: isPlaceInList ? checkboxCircleFill : checkboxBlankCircleLine,
            onAction: () => addPlaceToList(place._id, list.nameId, !isPlaceInList),
            keepOpened: true
          };
        })
      ]
    : [
        {
          text: `Rate ${place.name}`,
          icon: starFill,
          onAction: () => setIsAuthModalOpen(true)
        },
        'separator',
        {
          text: 'Bucket list',
          icon: checkboxBlankCircleLine,
          onAction: () => setIsAuthModalOpen(true)
        }
      ];

  return (
    <>
      <Dropdown controlledIsOpen={isOpen} controlledSetIsOpen={setIsOpen} items={items}>
        <div className="absolute top-0 right-0">
          <BaseButton
            type="button"
            buttonVariant="translucent"
            className={twMerge(
              'm-2 h-7 w-7 p-0 transition-opacity',
              isPlaceRated || isPlaceSaved
                ? ''
                : 'opacity-0 focus:opacity-100 peer-focus:opacity-100 group-hover:opacity-100 [@media(hover:none)]:opacity-100',
              isOpen ? 'opacity-100' : '',
              isLoading ? 'opacity-0' : ''
            )}
            icon={icon}
            iconSize={16}
            aria-label={`Options for ${place.name}`}
          />
        </div>
      </Dropdown>

      {myLists.length && isRateModalOpen ? (
        <RatePlaceModal
          place={place}
          controlledIsOpen={isRateModalOpen}
          controlledSetIsOpen={setIsRateModalOpen}
          onUpdated={refetchMyLists}
          session={session}
        />
      ) : null}

      {isAuthModalOpened ? (
        <AuthModal initialView="join" isOpen={isAuthModalOpened} setIsOpen={setIsAuthModalOpen} />
      ) : null}
    </>
  );
};

export default PlaceCardDropdown;
