const types: Record<string, string> = {
  city: 'City',
  town: 'Town',
  village: 'Village',
  island: 'Island',
  region: 'Region',
  countrySide: 'Countryside',
  peak: 'Peak',
  poi: 'Place',
  natureReserve: 'Nature reserve',
  nationalPark: 'National park'
};

export const placeTypes = Object.keys(types).map((key) => ({value: key, label: types[key] || key}));

const formatPlaceType = (placeType: PlaceType | string | undefined) => {
  return placeType ? types[placeType] || placeType : 'Place';
};

export default formatPlaceType;
