import {useEffect} from 'react';
import {useCommandState} from 'cmdk';
import {atom, useSetAtom} from 'jotai';

export const selectedResultState = atom<HumboSearchResult | undefined>(undefined);

type Props = {
  results: HumboSearchResult[];
};

const ResultsReporter = ({results}: Props) => {
  const selectedResultId = useCommandState((state) => state.value);

  // @ts-ignore
  const setSelectedResult = useSetAtom(selectedResultState);

  useEffect(() => {
    const selectedResult = results.find((result) => result._id === selectedResultId);

    setSelectedResult(selectedResult);
  }, [selectedResultId]);

  return null;
};

export default ResultsReporter;
