const s3Url = process.env.NEXT_PUBLIC_AWS_URL;

const getImageUrl = (path: string | undefined, suffix?: string) => {
  if (!path) return '';

  const pathParts = path.split('.');
  const suffixString = suffix ? `_${suffix}` : '';

  return `${s3Url}/${pathParts[0]}${suffixString}.${pathParts[1]}`;
};

export default getImageUrl;
