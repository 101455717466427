'use client';

import {useEffect} from 'react';
import {useAtomValue} from 'jotai';
import {twJoin} from 'tailwind-merge';
import {useAnimate} from 'framer-motion';
import {defaultUniforms} from 'src/components/earth/webgl/settings/uniforms';
import useGlslCanvas from 'src/components/earth/webgl/useGlslCanvas';
import {selectedResultState} from '../navigation/ResultsReporter';
import {searchUsedState} from 'src/hooks/useSearch';

// @ts-ignore
import fragmentShader from '../../../public/earth/shaders/earth.fragment.glsl';
// @ts-ignore
import vertexShader from '../../../public/earth/shaders/vertex.glsl';

const getRotation = (activeResult: HumboSearchResult | undefined, searchUsed: boolean) => {
  const location = activeResult?.location;

  if (!location || !searchUsed) return null;

  const [lat, lon] = location.coordinates;

  const latRad = (lat * Math.PI) / 180;
  const lonRad = Math.asin(lon / 90);

  return [lonRad, latRad];
};

const Earth = () => {
  const searchUsed = useAtomValue(searchUsedState);
  const activeResult = useAtomValue(selectedResultState);
  const targetRotation = getRotation(activeResult, searchUsed);

  const [labelScope, animateLabel] = useAnimate();

  const {onDrag, canvasRef} = useGlslCanvas({
    targetRotation,
    vertex: vertexShader,
    fragment: fragmentShader,
    uniforms: {
      ...defaultUniforms,
      uCloudsScale: -1,
      uCloudsSpeed: -1,
      uAmbientLight: 0.002
    }
  });

  useEffect(() => {
    if (!labelScope.current) return;

    if (activeResult && searchUsed) {
      animateLabel(labelScope.current, {opacity: 0, scale: 0.3}, {duration: 0}).then(() => {
        animateLabel(labelScope.current, {opacity: 1, scale: 1}, {duration: 0.3, delay: 0.3, ease: [0.16, 1, 0.3, 1]});
      });
    } else {
      animateLabel(labelScope.current, {opacity: 0}, {duration: 0.2});
    }
  }, [activeResult, searchUsed]);

  const hasResult = activeResult?.location && searchUsed;

  return (
    <div className="absolute w-full h-full flex flex-col justify-end items-center">
      <canvas
        ref={canvasRef}
        onPointerDown={hasResult ? undefined : onDrag}
        style={{touchAction: 'pan-y'}}
        className={twJoin('absolute w-full h-full opacity-0', !hasResult && 'cursor-grab active:cursor-grabbing')}
      />

      {hasResult ? (
        <div className="relative bottom-[35%]">
          <div
            ref={labelScope}
            className="text-[#252525] font-semibold bg-[rgba(255,255,255,0.8)] backdrop-blur-sm text-sm py-1 px-[11px] origin-bottom mb-[14px] rounded-full border border-solid border-[rgba(255,255,255,0.3)] shadow-[0px_8px_24px_rgba(0,0,0,0.5)]"
          >
            {activeResult?.name}
          </div>

          <div className="w-[6px] h-[6px] rounded-full bg-white opacity-80 absolute bottom-0 left-[calc(50%-3px)] shadow-[0px_2px_8px_rgba(0,0,0,0.5)]" />
        </div>
      ) : null}
    </div>
  );
};

export default Earth;
