export const defaultUniforms = {
  uQuality: Math.min(typeof window === 'undefined' ? 1 : window.devicePixelRatio, 2),
  uShouldRotate: 1,
  uRotationSpeed: 1,
  uResolution: typeof window === 'undefined' ? [100, 100] : [window.innerWidth, window.innerHeight],
  uPlanetPosition: [0, -1.5, 0],
  uRotationX: 0,
  uRotationY: 0,
  uRotationOffset: 0.8,
  uRotationOffsetY: 0,
  uPlanetRadius: 2.5,
  uBumpStrength: 0.01,
  uNoiseStrength: 0.2,
  uTerrainScale: 0.6,
  uCloudsDensity: 0.5,
  uCloudsScale: 10,
  uCloudsSpeed: 1.5,
  uAtmosphereColor: [0.25, 0.3, 0.5],
  // uAtmosphereColor: [0.05, 0.3, 0.9],
  uAtmosphereDensity: 0.4,
  uAmbientLight: 0.01,
  uSunIntensity: 2.8,
  sunDirectionXY: [1, 1],
  uDarkMode: 1
};

export type CustomUniforms = typeof defaultUniforms;
