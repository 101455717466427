import {twMerge} from 'tailwind-merge';

type Props = {
  className?: string;
};

const Skeleton = ({className}: Props) => {
  return (
    <div className={twMerge(['bg-[--subtle-color] rounded-md w-[60%] h-[20px] my-[3px] animate-pulse', className])} />
  );
};

export default Skeleton;
