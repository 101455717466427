'use client';

import {twMerge} from 'tailwind-merge';
import {addLine, anchorLine, bucket, building2Fill, buildingFill, peak, treeFill, village} from 'src/utils/icons';
import useSession from 'src/hooks/useSession';
import BaseButton from '../BaseButton';
import RemixIcon from '../RemixIcon';

const ovalClassName =
  'flex justify-center items-center w-14 h-14 rounded-full border border-[--subtle-color] shadow-[0_2px_5px_0_rgb(23_10_37_/_0.03)]';

const BucketListFeatureSection = () => {
  const {session} = useSession();

  return (
    <div className="flex flex-col justify-center items-center my-12 mx-[--page-padding]">
      <h2 className="text-xl mb-7 text-center">One list to gather all the places you must see.</h2>

      <div className="flex justify-between gap-8 items-center mb-10 px-4 [&>*]:bg-[--card-color] max-[800px]:gap-4 max-[600px]:gap-0 max-[600px]:w-full max-[600px]:[&>*]:-mx-1 max-[400px]:[&>*]:-mx-2">
        <div className={twMerge(ovalClassName, 'w-12 h-12 max-[500px]:w-10 max-[500px]:h-10')}>
          <RemixIcon icon={building2Fill} color="var(--gray-text)" size={20} />
        </div>

        <div className={twMerge(ovalClassName, 'w-14 h-14 max-[500px]:w-12 max-[500px]:h-12')}>
          <RemixIcon icon={anchorLine} color="var(--gray-text)" size={22} />
        </div>

        <div className={twMerge(ovalClassName, 'w-16 h-16 max-[500px]:w-14 max-[500px]:h-14')}>
          <RemixIcon icon={buildingFill} color="var(--gray-text)" />
        </div>

        <div className={twMerge(ovalClassName, 'w-20 h-20 max-[500px]:w-18 max-[500px]:h-18')}>
          <RemixIcon icon={bucket} color="var(--dynamic-purple)" size={40} />
        </div>

        <div className={twMerge(ovalClassName, 'w-16 h-16 max-[500px]:w-14 max-[500px]:h-14')}>
          <RemixIcon icon={treeFill} color="var(--gray-text)" />
        </div>

        <div className={twMerge(ovalClassName, 'w-14 h-14 max-[500px]:w-12 max-[500px]:h-12')}>
          <RemixIcon icon={village} color="var(--gray-text)" size={22} />
        </div>

        <div className={twMerge(ovalClassName, 'w-12 h-12 max-[500px]:w-10 max-[500px]:h-10')}>
          <RemixIcon icon={peak} color="var(--gray-text)" size={20} />
        </div>
      </div>

      <BaseButton
        href={session ? `/${session.nameId}/lists/bucket-list` : '/travel-bucket-list'}
        prefetch={false}
        className="min-w-[1px] shrink max-w-[stretch] bg-[--purple]"
        icon={session ? null : addLine}
        iconSize={24}
        buttonVariant="primary"
      >
        <span className="shrink-0">{session ? 'My Bucket List' : 'Create my Bucket List'}</span>
      </BaseButton>
    </div>
  );
};

export default BucketListFeatureSection;
