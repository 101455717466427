'use client';

import {type PointerEvent, useEffect, useRef} from 'react';
import {animate, useAnimate, useAnimationFrame, useMotionValue} from 'framer-motion';
import {type TextureData, loadTextures} from './loaders/textureLoader';
import type {CustomUniforms} from './settings/uniforms';
import useDarkMode from 'src/hooks/useDarkMode';

const fourModule = require('four');

const textures: TextureData[] = [
  {path: '3k_earth_color.jpeg', width: 3072, height: 1536},
  {path: '2k_earth_clouds.jpeg'},
  {path: '3k_earth_specular.jpeg', width: 3072, height: 1536},
  {path: '3k_earth_bump.jpg', width: 3072, height: 1536}
];

const damping = 0.95;

function flatten<T>(array: T[][]): T[] {
  return array.reduce((acc, val) => acc.concat(val), []);
}

const getSize = (canvas: HTMLCanvasElement) => {
  if (!canvas) return {width: 1, height: 1};

  const width = canvas.clientWidth;
  const height = canvas.clientHeight;

  return {width, height};
};

type MaterialOptions = any;

type Args = MaterialOptions & {
  canvas?: HTMLCanvasElement;
  targetRotation: number[] | null;
};

function getClientX(e: PointerEvent | TouchEvent) {
  return 'touches' in e ? e.touches[0].clientX : e.clientX;
}

const rotationYOffset = 0.4;

const useGlslCanvas = ({canvas: canvasArg, targetRotation, ...materialOptions}: Args) => {
  const {isDark} = useDarkMode();
  const rendererRef = useRef<any>(undefined);
  const uniformsRef = useRef<CustomUniforms | undefined>(undefined);
  const camera = useRef<any>(undefined);
  const mesh = useRef<any>(undefined);

  const [canvasRef, animateCanvas] = useAnimate();
  const cloudsDensity = useMotionValue(0.5);
  const rotationX = useMotionValue(1.5);
  const rotationY = useMotionValue(0);
  const velocityRef = useRef(0);

  const resumeRotation = () =>
    animate(rotationX, rotationX.get() + Math.PI, {duration: 40, ease: 'linear', repeat: Number.POSITIVE_INFINITY});

  useEffect(() => {
    if (!targetRotation) {
      resumeRotation();
      animate(cloudsDensity, 0.5, {duration: 1, ease: [0.16, 1, 0.3, 1]});
    } else {
      animate(rotationY, targetRotation[0] - rotationYOffset, {duration: 0.6, ease: [0.16, 1, 0.3, 1]});
      animate(rotationX, Math.PI / 2 - targetRotation[1] * 0.5, {duration: 0.6, ease: [0.16, 1, 0.3, 1]});
      animate(cloudsDensity, 0.2, {duration: 1, ease: [0.16, 1, 0.3, 1]});
    }
  }, [targetRotation]);

  useEffect(() => {
    const onResize = () => {
      if (!rendererRef.current) return;

      const {width, height} = getSize(canvasRef.current);
      const quality = mesh.current.material.uniforms.uQuality as number;

      rendererRef.current.setSize(width * quality, height * quality);
      mesh.current.material.uniforms.uResolution = [width, height];
    };

    const loadRenderer = async () => {
      const [uEarthColor, uEarthClouds, uEarthSpecular, uEarthBump] = await loadTextures(textures);

      const four = await fourModule;

      rendererRef.current = new four.WebGLRenderer({canvas: canvasRef.current});
      camera.current = new four.PerspectiveCamera();

      const material = new four.Material({
        ...materialOptions,
        // @ts-ignore
        uniforms: {...materialOptions.uniforms, uEarthColor, uEarthClouds, uEarthSpecular, uEarthBump}
      });

      const geometry = new four.Geometry({
        position: {
          size: 3,
          data: new Float32Array(
            flatten([
              [0, 0, 0],
              [1, 0, 0],
              [0, 1, 0],
              [0, 1, 0],
              [1, 0, 0],
              [1, 1, 0]
            ])
          )
        }
      });

      mesh.current = new four.Mesh(geometry, material);
      uniformsRef.current = mesh.current.material.uniforms as CustomUniforms;

      onResize();

      animateCanvas(canvasRef.current, {opacity: 1}, {duration: 1, ease: 'circIn'});

      window.addEventListener('resize', onResize, {passive: true});
    };

    loadRenderer();

    return () => {
      window.removeEventListener('resize', onResize);
      rendererRef.current?.clear();
      rendererRef.current?.setRenderTarget(null);
      rendererRef.current = undefined;
      uniformsRef.current = undefined;
      mesh.current = undefined;
      camera.current = undefined;
    };
  }, []);

  useAnimationFrame(() => {
    if (!uniformsRef.current || !rendererRef.current || !camera.current || !mesh.current) return;

    uniformsRef.current.uDarkMode = isDark ? 1 : 0;
    uniformsRef.current.uAtmosphereColor = isDark ? [0.05, 0.3, 0.9] : [0.5, 0.5, 1];

    if (velocityRef.current) {
      if (Math.abs(velocityRef.current) < 0.001) {
        velocityRef.current = 0;
        resumeRotation();
      }
      velocityRef.current *= damping;
      rotationX.set(rotationX.get() + velocityRef.current * 1);
    }

    uniformsRef.current.uRotationX = rotationX.get();
    uniformsRef.current.uRotationY = rotationY.get();
    uniformsRef.current.uCloudsDensity = cloudsDensity.get();
    rendererRef.current.render(mesh.current, camera.current);
  });

  const onDrag = (event: PointerEvent<HTMLCanvasElement>) => {
    const canvas = canvasRef.current;
    // @ts-ignore
    const initialX = 'touches' in event ? event.touches[0].clientX : event.clientX;
    const initialRotation = rotationX.get();

    rotationX.stop();
    velocityRef.current = 0;

    let lastRotation = initialRotation;

    const updateRotation = (e: PointerEvent | TouchEvent) => {
      const clientX = getClientX(e);
      const speedMultiplier = 4 / Math.max(window.innerWidth, window.innerHeight);
      const deltaPos = (clientX - initialX) * speedMultiplier;
      lastRotation = rotationX.get();
      rotationX.set(initialRotation + deltaPos);
    };

    // @ts-ignore
    canvas.addEventListener('pointermove', updateRotation);

    canvas.addEventListener(
      'pointerup',
      () => {
        // @ts-ignore
        canvas.removeEventListener('pointermove', updateRotation);

        const rotationDifference = rotationX.get() - lastRotation;

        if (rotationDifference) {
          velocityRef.current = rotationDifference;
        } else {
          resumeRotation();
        }
      },
      {once: true}
    );
  };

  return {onDrag, canvasRef};
};

export default useGlslCanvas;
