'use client';

import {twMerge} from 'tailwind-merge';
import type {ImageProps} from 'next/image';
import {useLayoutEffect, useRef} from 'react';
import FastImage from './FastImage';

type Props = ImageProps;

const FadingImage = (props: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);

  useLayoutEffect(() => {
    if (imageRef.current?.complete) {
      imageRef.current.style.opacity = '1';
    }
  });

  return (
    <FastImage
      {...props}
      ref={imageRef}
      className={twMerge(['opacity-0 transition-opacity duration-1000', props.className])}
      onLoad={() => {
        if (imageRef.current) {
          imageRef.current.style.opacity = '1';
        }
      }}
    />
  );
};

export default FadingImage;
