const fourModule = require('four');

export type TextureData = {
  path: string;
  width?: number;
  height?: number;
  options?: any;
};

async function loadTexture(url: string, width: number, height: number, samplerOptions?: Partial<any>) {
  const four = await fourModule;

  const imageData = await fetch(url);
  const imageBlob = await imageData.blob();

  const bitmap = await createImageBitmap(imageBlob, 0, 0, width, height, {
    imageOrientation: 'flipY'
  });

  return new four.Texture(
    bitmap,
    new four.Sampler({
      wrapS: 'repeat',
      wrapT: 'repeat',
      ...samplerOptions
    })
  );
}

export const loadTextures = async (texturesData: TextureData[]) => {
  return Promise.all(
    texturesData.map(({path, width = 2048, height = 1024, options}) => {
      const url = `/earth/textures/${path}`;

      return loadTexture(url, width, height, options);
    })
  );
};
